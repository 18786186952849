import React, { useState, useEffect } from 'react';

import Banner from '@images/svg/banner-technologies.svg';

import GainsOpenstack from '@molecules/group/gainsOpenstack';
import ApiBlock from '@molecules/group/apiBlock';
import StatsBlock from '@molecules/group/statsBlock';

import MainLayout from '@organisms/main/mainLayout';
import Header from '@organisms/main/header';
import Footer from '@organisms/main/footer';
import Part from '@organisms/main/part';

import lang from '@utils/lang';
import { usePageContext } from '@organisms/contexts/pageContext';

import './technologies.scss';

const TechnoPage = () => {
    const { _string } = lang;
    const { setPage } = usePageContext();

    const [binary, setBinary] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const [subListOpen, setSubListOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const toggleSubList = (e) => {
        e.preventDefault();
        setSubListOpen(!subListOpen);
    };

    // This is used for force re-render
    // TODO : Is there a better method to update first part when displayed on screen ?
    // (with react-intersection-observer and state, there is a flicker)
    useEffect(() => {
        if (!window) { return; }
        window.addEventListener('scroll', () => {
            setBinary((prev) => !prev);
        });
    }, []);

    useEffect(() => {
        if (!setPage) { return; }
        setPage('technologies');
    }, [setPage]);

    return (
        <MainLayout
            title={_string('technologies_titleTab')}
            socials={_string('technologies_socials')}
            footer={false}
            classTitle="technologies"
        >
            <div className="primary_block">
                <div
                    className="slidable"
                >
                    <Header
                        menuOpen={menuOpen}
                        toggleMenu={toggleMenu}
                        toggleSubList={toggleSubList}
                        setSubListOpen={setSubListOpen}
                        isSubListOpen={subListOpen}
                        style={{ zIndex: `${binary ? '3' : '4'}` }}
                    />
                </div>
                <div className="primary_block__content">
                    <div className="primary_block__text">
                        <h1 className="primary_block__title">
                            {_string('technologies_title')}
                        </h1>
                        <p className="primary_block__description"><strong>{_string('technologies_desc')}</strong></p>
                    </div>
                    <div className="primary_block__content__illu">
                        <Banner
                            className="primary_block__content__illu__main"
                        />
                    </div>
                </div>
            </div>
            <div className="secondary_block">
                <div className="slidable">
                    <Header
                        color="white"
                        menuOpen={menuOpen}
                        toggleMenu={toggleMenu}
                        toggleSubList={toggleSubList}
                        isSubListOpen={subListOpen}
                        setSubListOpen={setSubListOpen}
                    />
                </div>
                <Part
                    title={_string('openstack_catchphrase')}
                    desc={(
                        <>
                            <p>{_string('openstack_description_1')}</p>
                            <p>{_string('openstack_description_2')}</p>
                        </>
                    )}
                    className="block_openstack"
                    anchor="openstack"
                    separator={false}
                >
                    <GainsOpenstack />
                </Part>
                <Part
                    className="block_api"
                    anchor="api"
                    separator={false}
                >
                    <ApiBlock />
                </Part>
                <Part
                    className="block_stats"
                    anchor="stats"
                    separator={false}
                >
                    <StatsBlock />
                </Part>
                <Footer />
            </div>
        </MainLayout>
    );
};

export default TechnoPage;
