import React from 'react';

import { BasicButton } from '@nuage/ui-components';

import Yogi from '@images/svg/yogi.svg';

import GigabyteLogo from '@images/svg/logopartners/gigabyte.svg';
import AmdLogo from '@images/svg/logopartners/amd.svg';
import JuniperLogo from '@images/svg/logopartners/juniper.svg';
import NvidiaLogo from '@images/svg/logopartners/nvidia.svg';
import MicronLogo from '@images/svg/logopartners/micron.svg';
import CrsiLogo from '@images/svg/logopartners/crsi.svg';

import MobileGigabyteLogo from '@images/svg/logopartners/gigabyte-m.svg';
import MobileAmdLogo from '@images/svg/logopartners/amd-m.svg';
import MobileJuniperLogo from '@images/svg/logopartners/juniper-m.svg';
import MobileNvidiaLogo from '@images/svg/logopartners/nvidia-m.svg';
import MobileMicronLogo from '@images/svg/logopartners/micron-m.svg';
import MobileCrsiLogo from '@images/svg/logopartners/crsi-m.svg';

import lang from '@utils/lang';

import './statsBlock.scss';
import { dispatchGtagEvent } from '@utils/eventsManager';

const StatsBlock = () => {
    const { _string, _node } = lang;

    return (
        <div className="stats-block">
            <p className="stats-block__catchphrase">{_string('stats_catchphrase')}</p>

            <div className="stats-block__general-icon">
                <GigabyteLogo className="stats-block__main-icon" />
                <AmdLogo className="stats-block__main-icon" />
                <JuniperLogo className="stats-block__main-icon" />
                <NvidiaLogo className="stats-block__main-icon" />
                <MicronLogo className="stats-block__main-icon" />
                <CrsiLogo className="stats-block__main-icon" />
            </div>

            <ul className="stats-block__content">
                <li className="stats-block__elt">
                    <MobileGigabyteLogo className="stats-block__mobile-icon" />
                    <p>{_node('stats_gigabyte')}</p>
                </li>
                <li className="stats-block__elt">
                    <MobileAmdLogo className="stats-block__mobile-icon" />
                    <p>{_node('stats_amd')}</p>
                </li>
                <li className="stats-block__elt">
                    <MobileJuniperLogo className="stats-block__mobile-icon" />
                    <p>{_node('stats_juniper')}</p>
                </li>
                <li className="stats-block__elt">
                    <MobileNvidiaLogo className="stats-block__mobile-icon" />
                    <p>{_node('stats_nvidia')}</p>
                </li>
                <li className="stats-block__elt">
                    <MobileMicronLogo className="stats-block__mobile-icon" />
                    <p>{_node('stats_micron')}</p>
                </li>
                <li className="stats-block__elt">
                    <MobileCrsiLogo className="stats-block__mobile-icon" />
                    <p>{_node('stats_crsi')}</p>
                </li>
            </ul>

            <div className="stats-block__cta">
                <div className="stats-block__cta__content">
                    <p className="stats-block__cta__catchphrase">{_string('stats_footer')}</p>
                    <BasicButton
                        color="secondary"
                        link={_string('stats_link')}
                        size="large"
                        onClick={() => {
                            dispatchGtagEvent('click', {
                                event_category: 'cta',
                                event_label: 'inscription',
                            });
                        }}
                    >
                        {_string('stats_cta')}
                    </BasicButton>
                </div>
                <div className="stats-block__cta__illu">
                    <Yogi className="stats-block__cta__illu__content yogi" />
                </div>
            </div>
        </div>
    );
};

export default StatsBlock;
