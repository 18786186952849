import React, { useEffect, useState, useCallback } from 'react';

import Conveyor from '@images/svg/api/conveyor.svg';
import ConveyorBlob from '@images/svg/api/blob-conveyor.svg';
import Outlet from '@images/svg/api/outlet.svg';
import OutletBlob from '@images/svg/api/blob-outlet.svg';

import OxBlob from '@molecules/visual/oxevaBlob';

import lang from '@utils/lang';

import './apiBlock.scss';

const ApiBlock = () => {
    const { _string } = lang;

    const [offsetPage, setOffsetPage] = useState(0);

    const onScrollIndex = useCallback(() => {
        if (document) {
            setOffsetPage(document.documentElement.scrollTop);
        }
    }, []);

    useEffect(() => {
        if (window) {
            window.addEventListener('scroll', onScrollIndex);
        }

        return () => {
            window.removeEventListener('scroll', onScrollIndex);
        };
    }, [onScrollIndex]);

    return (
        <div className="api-block">
            <OxBlob
                color="primary"
                mainShape="M154.7,69.7c12.9,21.1,17.9,48.1,7.4,68.1s-36.3,33.3-58.6,31.3-41.2-19.2-55.4-41.5S24.3,78,33.7,59,71.5,29.2,96.2,31.4,141.8,48.7,154.7,69.7Z"
                className="api-block__blob api-block__blob__top"
                offset={(offsetPage * -1)}
            />
            <div className="api-block__main">
                <div className="api-block__illu">
                    <Outlet className="api-block__illu__static" />
                    <OutletBlob className="api-block__illu__anim" />
                </div>
                <div className="api-block__content">
                    <h2 className="api-block__title">{_string('api_main_catchphrase')}</h2>
                    <p className="api-block__text">{_string('api_main_text_1')}</p>
                    <p className="api-block__text">{_string('api_main_text_2')}</p>
                    <p className="api-block__text">{_string('api_main_text_3')}</p>
                </div>
            </div>
            <div className="api-block__how">
                <div className="api-block__content">
                    <h2 className="api-block__title">{_string('api_how_catchphrase')}</h2>
                    <p className="api-block__text">{_string('api_how_text_1')}</p>
                    <p className="api-block__text">{_string('api_how_text_2')}</p>
                </div>
                <div className="api-block__illu">
                    <Conveyor className="api-block__illu__static" />
                    <ConveyorBlob className="api-block__illu__anim" />
                </div>
            </div>
            <OxBlob
                color="primary-hover"
                mainShape="M154.7,69.7c12.9,21.1,17.9,48.1,7.4,68.1s-36.3,33.3-58.6,31.3-41.2-19.2-55.4-41.5S24.3,78,33.7,59,71.5,29.2,96.2,31.4,141.8,48.7,154.7,69.7Z"
                className="api-block__blob api-block__blob__bottom"
                offset={(offsetPage * -1)}
            />
        </div>
    );
};

export default ApiBlock;
