import React from 'react';

import SvgHypervisor from '@images/svg/openstack/hypervisor.svg';
import SvgNetwork from '@images/svg/openstack/network.svg';
import SvgDisk from '@images/svg/openstack/disk.svg';
import SvgNotif from '@images/svg/openstack/notif.svg';
import SvgMessages from '@images/svg/openstack/messages.svg';

import WhiteSvgHypervisor from '@images/svg/openstack/hypervisor-w.svg';
import WhiteSvgNetwork from '@images/svg/openstack/network-w.svg';
import WhiteSvgDisk from '@images/svg/openstack/disk-w.svg';
import WhiteSvgNotif from '@images/svg/openstack/notif-w.svg';
import WhiteSvgMessages from '@images/svg/openstack/messages-w.svg';

import lang from '@utils/lang';

import './gainsOpenstack.scss';

const GainsOpenstack = () => {
    const { _string } = lang;

    return (
        <div className="gains-openstack">
            <ul className="gains-openstack__list">
                <li className="gains-openstack__elt">
                    <WhiteSvgHypervisor className="gains-openstack__icon gains-openstack__icon-m" />
                    <SvgHypervisor className="gains-openstack__icon gains-openstack__icon-d" />
                    <p className="gains-openstack__title">{_string('openstack_hypervisors_title')}</p>
                    <p className="gains-openstack__text">{_string('openstack_hypervisors_text')}</p>
                </li>
                <li className="gains-openstack__elt">
                    <WhiteSvgNetwork className="gains-openstack__icon gains-openstack__icon-m" />
                    <SvgNetwork className="gains-openstack__icon gains-openstack__icon-d" />
                    <p className="gains-openstack__title">{_string('openstack_network_title')}</p>
                    <p className="gains-openstack__text">{_string('openstack_network_text')}</p>
                </li>
                <li className="gains-openstack__elt">
                    <WhiteSvgDisk className="gains-openstack__icon gains-openstack__icon-m" />
                    <SvgDisk className="gains-openstack__icon gains-openstack__icon-d" />
                    <p className="gains-openstack__title">{_string('openstack_disk_title')}</p>
                    <p className="gains-openstack__text">{_string('openstack_disk_text')}</p>
                </li>
                <li className="gains-openstack__elt">
                    <WhiteSvgNotif className="gains-openstack__icon gains-openstack__icon-m" />
                    <SvgNotif className="gains-openstack__icon gains-openstack__icon-d" />
                    <p className="gains-openstack__title">{_string('openstack_notif_title')}</p>
                    <p className="gains-openstack__text">{_string('openstack_notif_text')}</p>
                </li>
                <li className="gains-openstack__elt">
                    <WhiteSvgMessages className="gains-openstack__icon gains-openstack__icon-m" />
                    <SvgMessages className="gains-openstack__icon gains-openstack__icon-d" />
                    <p className="gains-openstack__title">{_string('openstack_messages_title')}</p>
                    <p className="gains-openstack__text">{_string('openstack_messages_text')}</p>
                </li>
            </ul>
        </div>
    );
};

export default GainsOpenstack;
